import React from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <div className="">
        <footer
          className="p-3 pt-5 mt-4 border-top"
          style={{ backgroundColor: "#dbdbdb", fontSize: "20px" }}
        >
          <div className="d-flex justify-content-between align-items-end">
            <div className="mb-1 col-md-4">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "120px", height: "70px" }}
              />
            </div>

            <div className="col-md-5 row mx-0 p-0 d-md-flex d-none">
              <div className="mb-3 col-md-6 ps-0">
                <h4>Explore</h4>
              </div>
              <div className="mb-3 col-md-6 ps-0">
                <h4>Legal</h4>
              </div>
            </div>
            <div className="mb-3 col-md-3 d-md-block d-none">
              <h4>Follow Us</h4>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-4 ps-0">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nesciunt, provident necessitatibus, fugiat molestiae dignissimos
                expedita
              </p>
            </div>
            <div className="col-md-5 row mx-0 p-0">
              <div className="col-md-6 ps-0">
                <h4 className="d-md-none">Explore</h4>
                <ul className="  list-unstyled">
                  <li className="mb-3">
                    <Link className="text-dark text-decoration-none" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="text-dark  text-decoration-none" to="/">
                      Products
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="text-dark  text-decoration-none" to="/">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 ps-0">
                <h4 className="d-md-none">Legal</h4>

                <ul className="  list-unstyled">
                  <li className="mb-3">
                    <Link className="text-dark text-decoration-none" to="/">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="text-dark  text-decoration-none" to="/">
                      Terms of Service
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="text-dark  text-decoration-none" to="/">
                      Business Terms of Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 ps-0">
              <h4 className="d-md-none">Follow Us</h4>
              <div>
                <ul className="nav fs-1 list-unstyled d-flex">
                  <li>
                    <Link className="text-dark" to="/">
                      <FaFacebook />
                    </Link>
                  </li>
                  <li className="ms-3">
                    <Link className="text-dark" to="/">
                      <FaInstagram />
                    </Link>
                  </li>
                  <li className="ms-3">
                    <Link className="text-dark" to="/">
                      <FaTwitter />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <h4 className="mb-2">Support</h4>
                <p>exmaple@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="border-top border-dark  text-center">
            <div className="text-dark d-flex py-3 align-items-center justify-content-center">
              © 2023 IRK, All rights reserved
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
