import { toast } from "react-toastify";

export function ToastModal(text, type) {
  const settings = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    pauseOnFocusLoss: false,
    theme: "dark",
  };
  if (type === "success") {
    return toast.success(text, settings);
  } else if (type === "error") {
    return toast.error(text, settings);
  } else if (type === "info") {
    return toast.info(text, settings);
  }
}
