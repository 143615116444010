import React, { useState } from "react";

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Form,
  Input,
  Select,
  Upload,
  message,
  Checkbox,
  Radio,
} from "antd";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const AntForm = () => {
  let categories = [{ label: "test", value: "test " }];
  const [form] = Form.useForm();

  const handleAddDimension2 = (e) => {
    form.setFieldsValue({
      dimensions: [
        ...(form.getFieldValue("dimensions") || []),
        {
          radio_group: "a",
          question: "",
          score: "",
          options: [{ option: "" }],
        },
      ],
    });
  };

  const onFinish = (value) => {
    console.log("va", value);
  };

  return (
    <div className="container">
      <Form
        form={form}
        layout="vertical"
        style={{
          padding: "8px 0px",
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="row mx-0"
      >
        <Form.Item
          className="col-md-4"
          name="category_id"
          label="Category"
          rules={[
            {
              required: true,
              message: "Category required",
            },
          ]}
        >
          <Select options={categories} placeholder="Select category" />
        </Form.Item>

        <Form.Item
          className="col-md-4"
          label="Product Name (Eng)"
          name="questionnaire_title"
          rules={[
            {
              required: true,
              message: "Please input your Product Name!",
            },
          ]}
        >
          <Input maxLength={200} />
        </Form.Item>

        <Form.List
          name="dimensions"
          initialValue={[
            {
              radio_group: "a",
              question: "",
              score: "",
              options: [{ option: "" }, { option: "" }, { option: "" }],
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, dimensionIndex) => (
                <div
                  key={field.key}
                  className="row mx-0 bg-light mb-2 p-2 rounded border-bottom"
                >
                  <Form.Item
                    className="col-12"
                    name={[field.name, "radio_group"]}
                    key={[field.key, "radio_group"]}
                    label="Radio.Group"
                  >
                    <Radio.Group>
                      <Radio value="a">item 1</Radio>
                      <Radio value="b">item 2</Radio>
                      <Radio value="c">item 3</Radio>
                      <Radio value="e">item 4</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      ["a", "b", "c", "d"].includes(
                        currentValues.dimensions[field.name].radio_group
                      )
                    }
                  >
                    {({ getFieldValue }) => {
                      return getFieldValue("dimensions")[field.name]
                        .radio_group === "a" ? (
                        <>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Dimension"
                            name={[field.name, "question"]}
                            key={[field.key, "question"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Score"
                            name={[field.name, "score"]}
                            key={[field.key, "score"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>
                          <Form.List
                            name={[field.name, "options"]}
                            initialValue={[
                              { option: "" },
                              { option: "" },
                              { option: "" },
                            ]}
                          >
                            {(
                              optionFields,
                              { add: addOption, remove: removeOption }
                            ) => (
                              <>
                                {optionFields.map(
                                  (optionField, optionIndex) => (
                                    <div key={optionField.key}>
                                      <Form.Item
                                        {...optionField}
                                        className="col-md-4 ps-md-0"
                                        label={`Option ${optionIndex + 1}`}
                                        name={[optionField.name, "option"]}
                                        key={[optionField.key, "option"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter an option",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Option"
                                          maxLength={100}
                                        />
                                      </Form.Item>
                                      {optionFields.length > 3 && (
                                        <Button
                                          type="primary"
                                          className="d-flex align-items-center mb-2"
                                          danger
                                          icon={<MinusCircleOutlined />}
                                          onClick={() =>
                                            removeOption(optionField.name)
                                          }
                                          style={{ width: "fit-content" }}
                                        >
                                          Remove Option
                                        </Button>
                                      )}
                                    </div>
                                  )
                                )}
                                <Button
                                  type="dashed"
                                  onClick={() => addOption()}
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                >
                                  Add Option
                                </Button>
                              </>
                            )}
                          </Form.List>
                          {/* <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 1"
                            name={[field.name, "option_a"]}
                            key={[field.key, "option_a"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 2"
                            name={[field.name, "option_b"]}
                            key={[field.key, "option_b"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 3"
                            name={[field.name, "option_c"]}
                            key={[field.key, "option_c"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item> */}
                        </>
                      ) : getFieldValue("dimensions")[field.name]
                          .radio_group === "b" ? (
                        <>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Dimension"
                            name={[field.name, "question"]}
                            key={[field.key, "question"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Score"
                            name={[field.name, "score"]}
                            key={[field.key, "score"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 1"
                            name={[field.name, "option_a"]}
                            key={[field.key, "option_a"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="answer"
                              maxLength={100}
                            />
                          </Form.Item>
                        </>
                      ) : getFieldValue("dimensions")[field.name]
                          .radio_group === "c" ? (
                        <>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Dimension"
                            name={[field.name, "question"]}
                            key={[field.key, "question"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Score"
                            name={[field.name, "score"]}
                            key={[field.key, "score"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 1"
                            name={[field.name, "option_a"]}
                            key={[field.key, "option_a"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="answer" maxLength={100} />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Dimension"
                            name={[field.name, "question"]}
                            key={[field.key, "question"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="Score"
                            name={[field.name, "score"]}
                            key={[field.key, "score"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="Dimension" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 1"
                            name={[field.name, "option_a"]}
                            key={[field.key, "option_a"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="answer" maxLength={100} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            className="col-md-4  ps-md-0"
                            label="option 1"
                            name={[field.name, "option_b"]}
                            key={[field.key, "option_b"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a dimension",
                              },
                            ]}
                          >
                            <Input placeholder="answer" maxLength={100} />
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.Item>

                  <div className="w-100 text-end d-flex  justify-content-end">
                    {fields.length > 1 && (
                      <Button
                        type="primary"
                        className="mb-2 d-flex align-items-center "
                        danger
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(field.name)}
                        style={{ width: "fit-content" }}
                      >
                        Remove Dimension
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <div className="w-100 text-end d-flex  justify-content-end">
                <Button
                  type="primary"
                  block
                  className="mb-2 d-flex align-items-center "
                  style={{ width: "fit-content" }}
                  onClick={handleAddDimension2}
                  icon={<PlusOutlined />}
                >
                  Add Dimension
                </Button>
              </div>
            </>
          )}
        </Form.List>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AntForm;
