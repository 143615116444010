import React, { Suspense, useEffect, useLayoutEffect } from "react";
import "./App.css";
import MainRoutes from "./routes";
import {
  unstable_HistoryRouter as HistoryRoute,
  useLocation,
} from "react-router-dom";
import history from "./utils/history";
import AdminRoutes from "./routes/admin";
import { getLoggedUser } from "./redux/actions/authActions";
import { connect } from "react-redux";
import Loader from "./Loader";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
function App({ getLoggedUser, data, isL }) {
  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      getLoggedUser(token);
    }
  }, [token, getLoggedUser]);

  return (
    <Suspense fallback={<Loader />}>
      <HistoryRoute history={history}>
        <ScrollToTop />
        <MainRoutes isUser={data?.type === "user"} isL={isL} />
        {data?.type === "admin" && <AdminRoutes />}
      </HistoryRoute>
    </Suspense>
  );
}
const mapStateToProps = (state) => {
  return {
    isSuc: state.auth.isSuc,
    isL: state.auth.isL,
    data: state.auth.data,
  };
};
export default connect(mapStateToProps, { getLoggedUser })(App);
