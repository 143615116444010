import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const DashboardPage = lazy(() => import("../Pages/admin/DashboardPage"));
const ProfilePage = lazy(() => import("../Pages/admin/ProfilePage"));
const EditProfilePage = lazy(() => import("../Pages/admin/UpdateProfilePage"));

const ProductsPage = lazy(() => import("../Pages/admin/ProductsPage"));
const AddProductsPage = lazy(() =>
  import("../Pages/admin/ProductsPage/AddProductPage")
);
const EditProductPage = lazy(() =>
  import("../Pages/admin/ProductsPage/EditProductPage")
);
const CategoriesPage = lazy(() => import("../Pages/admin/CategoriesPage"));
const UsersPage = lazy(() => import("../Pages/admin/UsersPage"));
const OrdersPage = lazy(() => import("../Pages/admin/OrdersPage"));
const CreateShipmentPage = lazy(() =>
  import("../Pages/admin/shipment/CreateShipmentPage")
);

const DiscountsPage = lazy(() => import("../Pages/admin/DiscountPage"));

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/admin" element={<DashboardPage />} />
      <Route path="/admin/profile" element={<ProfilePage />} />
      <Route path="/admin/profile/edit" element={<EditProfilePage />} />
      <Route path="/admin/users" element={<UsersPage />} />

      <Route path="/admin/products" element={<ProductsPage />} />
      <Route path="/admin/products/add" element={<AddProductsPage />} />
      <Route path="/admin/products/edit/:id" element={<EditProductPage />} />

      <Route path="/admin/categories" element={<CategoriesPage />} />
      <Route path="/admin/orders" element={<OrdersPage />} />
      <Route
        path="/admin/orders/create_shipment/:id/:user_id"
        element={<CreateShipmentPage />}
      />

      <Route path="/admin/discounts" element={<DiscountsPage />} />
    </Routes>
  );
};

export default AdminRoutes;
