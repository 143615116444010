export const usersConstants = {
  UReq: "U_REQ",
  UErr: "U_ERR",
  USuc: "U_SUC",
  UClr: "U_CLR",
  UAddAtt: "U_ADD_ATT",
  UUptAtt: "U_UPT_ATT",
  UDelAtt: "U_DEL_ATT",
};

export const authConstants = {
  AUClr: "AU_Clr",
  AUReq: "AU_REQ",
  AUErr: "AU_ERR",
  AUSuc: "AU_SUC",
};
export const modalConstants = {
  MPReq: "MP_REQ",
  MPHide: "MP_HIDE",
  MPSuc: "MP_SUC",
};

export const productsConstants = {
  ProdReq: "PROD_REQ",
  ProdErr: "PROD_ERR",
  ProdSuc: "PROD_SUC",
  ProdList: "PROD_LIST",
  ProdList2: "PROD_LIST_2",
  ProdData: "PROD_DATA",
  ProdClr: "PROD_CLR",
  ProdAddAtt: "PROD_ADD_ATT",
  ProdUptAtt: "PROD_UPT_ATT",
  ProdDelAtt: "PROD_DEL_ATT",
};

export const ordersConstants = {
  OrdReq: "ORD_REQ",
  OrdErr: "ORD_ERR",
  OrdSuc: "ORD_SUC",
  OrdList: "ORD_LIST",
  OrdList2: "ORD_LIST_2",
  OrdData: "ORD_DATA",
  OrdClr: "ORD_CLR",
  OrdAddAtt: "ORD_ADD_ATT",
  OrdUptAtt: "ORD_UPT_ATT",
  OrdDelAtt: "ORD_DEL_ATT",
};
export const localStoreConstant = {
  lang: "LANG",
  cartS: "CART_STORAGE",
};
