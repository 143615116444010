import { ordersConstants } from "../constant";

const {
  OrdReq,
  OrdErr,
  OrdSuc,
  OrdList,
  OrdList2,
  OrdData,
  OrdClr,
  OrdAddAtt,
  OrdDelAtt,
  OrdUptAtt,
} = ordersConstants;
const iS = {
  isSuc: false,
  isErr: false,
  isL: false,
  list: [],
};

export const OrderReducer = (state = iS, action) => {
  let listTemp = state.list;

  switch (action.type) {
    case OrdSuc:
      return { ...state, isErr: false, isSuc: true, isL: false };
    case OrdList:
      return { ...state, list: action.payload };
    case OrdList2:
      return { ...state, list2: action.payload };
    case OrdData:
      return { ...state, data: action.payload };

    case OrdReq:
      return { ...state, isErr: false, isSuc: false, isL: true };
    case OrdErr:
      return { ...state, isErr: true, isSuc: false, isL: false };
    case OrdClr:
      return { ...state, isErr: false, isSuc: false, isL: false };
    case OrdDelAtt:
      if (listTemp && listTemp.length > 0) {
        listTemp = listTemp.filter(
          (i) => Number(i.id) !== Number(action.payload.id)
        );
      }

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    case OrdUptAtt:
      if (listTemp && listTemp.length > 0)
        listTemp = listTemp.map((i) => {
          if (Number(i.id) === Number(action.payload.id)) i = action.payload;
          return i;
        });

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    case OrdAddAtt:
      if (listTemp && listTemp.length > 0)
        listTemp = listTemp.conOrd([action.payload]);
      else if (listTemp) {
        listTemp = [].conOrd([action.payload]);
      }

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    default:
      return state;
  }
};
