import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { ToastModal } from "../toast";
import validator from "validator";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Login = ({ loginUser, isSuc, setShow, setIsForgot }) => {
  const [validated, setValidated] = useState(false);
  const [Data, setData] = useState({
    email: "",
    password: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuc) {
      setShow(false);
    }
    // eslint-disable-next-line
  }, [isSuc]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (!validator.isEmail(Data.email)) {
        ToastModal(t("Email is not valid"), "error");
      } else {
        await loginUser(Data);
      }
    }
    setValidated(true);
  };

  return (
    <Form
      className="p-3"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3" controlId="formBasicEmail1">
        <Form.Label>{t("login.email")}</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={Data.email}
          maxLength={45}
          onChange={(e) => setData({ ...Data, email: e.target.value })}
          required
        />
        <Form.Control.Feedback type="invalid">
          {t("Please provide valid email")}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formBasicPassword1">
        <Form.Label>{t("login.password")}</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          value={Data.password}
          maxLength={45}
          onChange={(e) => setData({ ...Data, password: e.target.value })}
          required
        />
        <Form.Control.Feedback type="invalid">
          {t("Please provide valid password")}
        </Form.Control.Feedback>
      </Form.Group>
      <p className="text-end w-100 mb-3">
        <button
          type="button"
          className="border-0 bg-transparent text-decoration-underline test-primary"
          onClick={() => setIsForgot(true)}
        >
          {t("login.forgot_password")}?
        </button>
      </p>
      <div className="w-100 mb-3 mx-auto text-center">
        <button
          type="submit "
          className="btn btn-primary col-md-6 rounded-pill"
        >
          {t("login.singin")}
        </button>
      </div>

      <p>
        {t("login.register")}
        <Link to="/registration" onClick={() => setShow(false)}>
          {t("login.registerbtn")}
        </Link>
      </p>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    isSuc: state.auth.isSuc,
  };
};
export default connect(mapStateToProps, { loginUser })(Login);
