import { productsConstants } from "../constant";

const {
  ProdReq,
  ProdErr,
  ProdSuc,
  ProdList,
  ProdList2,
  ProdData,
  ProdClr,
  ProdAddAtt,
  ProdDelAtt,
  ProdUptAtt,
} = productsConstants;
const iS = {
  isSuc: false,
  isErr: false,
  isL: false,
  list: [],
};

export const ProductsReducer = (state = iS, action) => {
  let listTemp = state.list;

  switch (action.type) {
    case ProdSuc:
      return { ...state, isErr: false, isSuc: true, isL: false };
    case ProdList:
      return { ...state, list: action.payload };
    case ProdList2:
      return { ...state, list2: action.payload };

    case ProdData:
      return { ...state, data: action.payload };

    case ProdReq:
      return { ...state, isErr: false, isSuc: false, isL: true };
    case ProdErr:
      return { ...state, isErr: true, isSuc: false, isL: false };
    case ProdClr:
      return { ...state, isErr: false, isSuc: false, isL: false };
    case ProdDelAtt:
      if (listTemp && listTemp.length > 0) {
        listTemp = listTemp.filter(
          (i) => Number(i.id) !== Number(action.payload.id)
        );
      }

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    case ProdUptAtt:
      if (listTemp && listTemp.length > 0)
        listTemp = listTemp.map((i) => {
          if (Number(i.id) === Number(action.payload.id)) i = action.payload;
          return i;
        });

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    case ProdAddAtt:
      if (listTemp && listTemp.length > 0)
        listTemp = listTemp.concat([action.payload]);
      else if (listTemp) {
        listTemp = [].concat([action.payload]);
      }

      return {
        ...state,
        list: listTemp,
        isL: false,
      };

    default:
      return state;
  }
};
